import { useState } from "react";
import { getEquipmentControl } from "../api/request";
import FormEquipment from "../components/FormEquipment";
import InfoBox from "../components/InfoBox";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { URL } from "../utils/utils";
import axios from "axios";
import Cookies from "js-cookie";

function AddEquipment() {
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();

    const handleInsertEquipment = async (form, number) => {
        try {
            const res = await getEquipmentControl(number);
            if (!res.status) {
                setInfo(res.message);
                return;
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const config = {
                headers: {
                    authorization: `Bearer ${Cookies.get('skysan-token')}`
                }
            };

            const res = await axios.post(`${URL}api/equipments/insert-equipment`, form, config);
            if (res.data.status) {
                setInfo(res.data.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Ekipman ekle</p>
                <div
                    onClick={() => {
                        navigate("/equipments");
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Ekipmanlar</p>
                </div>
            </div>
            <div className="w-full 
                            bg-white
                            shadow-lg
                            rounded
                            p-5">

                <FormEquipment
                    buttonTitle={"Kaydet"}
                    onToggle={handleInsertEquipment}
                    equipment={{
                        number: "",
                        location: "",
                        equipmentCategory: "",
                        equipmentName: "",
                        brand: "",
                        manufactureYear: "",
                        serialNo: "",
                        capacity: "",
                        capacityType: "",
                        workingPressure: "",
                        piece: "1",
                        SAPEquipmentNo: "",
                        assetLabelNo: "",
                        producionStopIsItNecessary: "HAYIR",
                        controlPeriod: 90,
                        equipmentExplanation: "",
                        isActive: true
                    }} />
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default AddEquipment;